import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { usePdfGenerator } from './usePdfGenerator';

// Import utilities from your existing files
import { formatDate, formatTime, formatDateRange } from '../components/SubmissionDetail/utils/formatters';
import { 
  isTruthyResponse, 
  getSelectedOptions, 
  getNumericValue,
  hasFollowUpData,
  getFollowUpResponses
} from '../components/SubmissionDetail/utils/responseUtils';
import { normalizeTextResponses } from '../services/firebase/responseProcessing';
import { convertToDate } from '../services/firebase/responseProcessing';

// Define FirestoreTimestamp interface
interface FirestoreTimestamp {
  seconds: number;
  nanoseconds: number;
}

export const useExport = () => {
  const [exporting, setExporting] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);
  const { generatePdf } = usePdfGenerator();

  // Update the getCommentForQuestion function to be more thorough
  const getCommentForQuestion = (questionId: string, submission: any): string => {
    if (!submission) return '';
    
    // Check in dedicated comments object
    if (submission.comments && submission.comments[questionId]) {
      return submission.comments[questionId];
    }
    
    // Check in responses for comment fields
    if (submission.responses) {
      const possibleCommentKeys = [
        `${questionId}_comment`,
        `comment_${questionId}`,
        `${questionId}Comment`,
        `${questionId}-comment`,
        `${questionId}_comments`,
        `comments_${questionId}`
      ];
      
      for (const key of possibleCommentKeys) {
        if (submission.responses[key]) {
          return submission.responses[key];
        }
      }
    }
    
    return '';
  };

  // Helper function to get follow-up response for a question
  const getFollowUpResponse = (questionId: string, submission: any): string | null => {
    if (!submission) return null;
    
    const responses = submission.responses || {};
    
    // Check all possible locations where follow-ups might be stored
    if (responses[`${questionId}_followUp`]) {
      return responses[`${questionId}_followUp`];
    }
    
    if (responses[`followUp_${questionId}`]) {
      return responses[`followUp_${questionId}`];
    }
    
    if (responses[`${questionId}followUp`]) {
      return responses[`${questionId}followUp`];
    }
    
    return null;
  };

  // Helper function to get option-specific follow-up responses
  const getOptionFollowUp = (questionId: string, optionValue: string, submission: any): string | null => {
    if (!submission) return null;
    
    const responses = submission.responses || {};
    
    // Check all possible locations for option-specific follow-ups
    const possibleKeys = [
      `${questionId}_followUp_${optionValue}`,
      `followUp_${questionId}_${optionValue}`,
      `${questionId}_option_${optionValue}_followUp`,
      `${questionId}${optionValue}followUp`
    ];
    
    for (const key of possibleKeys) {
      if (responses[key] !== undefined && responses[key] !== null) {
        return responses[key];
      }
    }
    
    return null;
  };

  // Helper function to get "Other" response for a question
  const getOtherResponse = (questionId: string, submission: any): string | null => {
    if (!submission) return null;
    
    const responses = submission.responses || {};
    
    // Check all possible locations for "Other" responses
    const possibleKeys = [
      `${questionId}_other`, 
      `other_${questionId}`,
      `${questionId}other`
    ];
    
    for (const key of possibleKeys) {
      if (responses[key] !== undefined && responses[key] !== null) {
        return responses[key];
      }
    }
    
    return null;
  };

  // Helper function to format responses consistently using the renderer system
  const formatResponseForExport = (question: any, submission: any): string => {
    const responses = submission?.responses || {};
    const response = responses[question.id];

    if (response === undefined || response === null) {
      return '';
    }

    try {
      switch (question.type) {
        case 'date':
          return formatDate(response);
        
        case 'date-range':
          return formatDateRange(response);
          
        case 'time':
          return formatTime(response);
        
        case 'text':
        case 'long-text':
          // Convert to string
          return String(response || '');
          
        case 'checkbox':
          if (question.checkboxStyle === 'yes-no') {
            const isTrue = isTruthyResponse(response);
            return isTrue ? 'Yes' : 'No';
          } else if (question.checkboxStyle === 'single') {
            return isTruthyResponse(response) ? 'Checked' : 'Unchecked';
          } else {
            // Multi-checkbox
            const options = getSelectedOptions(response);
            return options.join(', ');
          }
          
        case 'select':
          return String(response || '');
          
        case 'multi-select':
          const multiOptions = getSelectedOptions(response);
          return multiOptions.join(', ');
          
        case 'repeatable':
          // First try to get items from repeatableItems (preferred location)
          let items = submission?.repeatableItems?.[question.id];
          
          // If not found there, check in responses
          if (!items || !Array.isArray(items) || items.length === 0) {
            items = Array.isArray(responses[question.id]) ? responses[question.id] : [];
          }
          
          // Also check for object with numeric keys pattern
          if ((!items || items.length === 0) && typeof response === 'object' && response !== null) {
            const possibleArrayItems = Object.keys(response)
              .filter(key => !isNaN(Number(key)))
              .map(key => response[key]);
              
            if (possibleArrayItems.length > 0) {
              items = possibleArrayItems;
            }
          }
          
          if (!items || !Array.isArray(items) || items.length === 0) {
            return 'No items';
          }
          
          // Format each repeatable item into a structured string optimized for Excel
          return items.map((item, index) => {
            const itemParts: string[] = [];
            
            // Format each subfield
            if (question.subFields && Array.isArray(question.subFields)) {
              question.subFields.forEach((subField: any) => {
                const subValue = item[subField.id];
                if (subValue !== undefined && subValue !== null) {
                  let formattedValue = subValue;
                  
                  // Format other types appropriately
                  if (typeof formattedValue === 'boolean') {
                    formattedValue = formattedValue ? 'Yes' : 'No';
                  } else if (Array.isArray(formattedValue)) {
                    formattedValue = formattedValue.join(', ');
                  } else if (formattedValue instanceof Date) {
                    formattedValue = format(formattedValue, 'MMM d, yyyy');
                  } else if (typeof formattedValue === 'object' && formattedValue !== null) {
                    // Handle date objects from Firestore
                    if (formattedValue.seconds) {
                      formattedValue = format(new Date(formattedValue.seconds * 1000), 'MMM d, yyyy');
                    } else {
                      try {
                        formattedValue = JSON.stringify(formattedValue);
                      } catch (e) {
                        formattedValue = '[Complex Object]';
                      }
                    }
                  }
                  
                  itemParts.push(`${subField.label}: ${formattedValue}`);
                }
              });
            } else {
              // If subFields isn't defined, just show key-value pairs
              Object.entries(item).forEach(([key, value]) => {
                if (value !== undefined && value !== null && key !== 'id') {
                  itemParts.push(`${key}: ${value}`);
                }
              });
            }
            
            // Join all parts for this item
            return `Item ${index + 1}: {${itemParts.join('; ')}}`;
          }).join('\n');
          
        case 'number':
          // Format number response
          const numValue = getNumericValue(response);
          return numValue !== null ? String(numValue) : '';
          
        case 'rating':
          // Format rating response
          return response !== null && response !== undefined ? String(response) : '';
          
        default:
          return String(response || '');
      }
    } catch (err) {
      console.error(`Error formatting response for question ${question.id}:`, err);
      return 'Error formatting response';
    }
  };

  // NEW FUNCTION: Optimized export that handles large datasets through batch processing
  const exportLargeDataset = async (
      formTitle: string,
      questions: any[],
      submissions: any[],
      exportFormat: 'xlsx' | 'csv' = 'xlsx',
      batchSize: number = 100
    ) => {
    setExporting(true);
    setProgress(0);
    
    try {
      console.log(`Starting optimized ${exportFormat} export for ${submissions.length} submissions with batch size ${batchSize}`);
      
      // Create headers for the Excel/CSV file
      const headers = ['Submission ID', 'Email', 'Submission Date'];
      
      // Filter to include sections but exclude non-data types
      const exportableQuestions = questions.filter(q => 
        !['instructions', 'image'].includes(q.type)
      );
      
      // Create a map to track sections for prefixing child question labels
      const sectionMap = new Map();
      
      // Identify sections for prefixing
      questions.forEach(question => {
        if (question.type === 'section') {
          sectionMap.set(question.id, question.label || 'Section');
        }
      });
      
      // Add question labels with section prefixes
      exportableQuestions.forEach(question => {
        let prefix = '';
        
        // If this is a section, no prefix
        if (question.type === 'section') {
          headers.push(question.label || 'Section');
        } else {
          // If this question belongs to a section, prefix with section name
          if (question.sectionId && sectionMap.has(question.sectionId)) {
            prefix = `${sectionMap.get(question.sectionId)}: `;
          }
          
          headers.push(`${prefix}${question.label || question.id}`);
        }
      });
      
      // Add general comments
      headers.push('General Comments');

      // Process submissions in batches to reduce memory usage
      let allRows: any[] = [];
      let processedCount = 0;
      
      // Calculate total number of batches
      const totalBatches = Math.ceil(submissions.length / batchSize);
      
      // Process each batch
      for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
        // Get current batch of submissions
        const start = batchIndex * batchSize;
        const end = Math.min(start + batchSize, submissions.length);
        const batchSubmissions = submissions.slice(start, end);
        
        // Process this batch
        const batchRows = batchSubmissions.map(submission => {
          // Format submission date
          let submissionDate = '';
          try {
            const dateObj = submission.submittedAt?.toDate ? 
              submission.submittedAt.toDate() : 
              convertToDate(submission.submittedAt);
              
            submissionDate = dateObj ? format(dateObj, 'MMM d, yyyy h:mm a') : 'Unknown date';
          } catch (e) {
            submissionDate = 'Unknown date';
          }
          
          // Create base row with metadata
          const row = [
            submission.id || 'Unknown',
            submission.email || 'Anonymous',
            submissionDate
          ];
          
          // Add responses for each question
          exportableQuestions.forEach(question => {
            // For section questions, check for direct responses or section_q_[id] patterns
            if (question.type === 'section') {
              // Check for direct section response
              let sectionValue = submission.responses?.[question.id] || '';
              
              // If no direct response, look for section_q_[id] patterns
              if (!sectionValue) {
                const sectionResponses: string[] = [];
                if (submission.responses) {
                  Object.entries(submission.responses).forEach(([key, value]) => {
                    if (key.startsWith(`section_q_`) && typeof value === 'string' && value.trim() !== '') {
                      sectionResponses.push(value as string);
                    }
                  });
                }
                sectionValue = sectionResponses.join('; ');
              }
              
              row.push(sectionValue);
            } else {
              // Standard question response
              row.push(formatResponseForExport(question, submission));
            }
          });
          
          // Add general comments
          row.push(submission.generalComments || '');
          
          return row;
        });
        
        // Add batch rows to all rows
        allRows = allRows.concat(batchRows);
        
        // Update progress
        processedCount += batchSubmissions.length;
        setProgress(Math.round((processedCount / submissions.length) * 100));
        
        // Give the browser a chance to breathe between batches
        if (batchIndex < totalBatches - 1) {
          await new Promise(resolve => setTimeout(resolve, 0));
        }
      }
      
      // Create workbook
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...allRows]);
      
      // Auto-size columns
      worksheet['!cols'] = Array(headers.length).fill({ wch: 22 }); // Wider columns for better readability
      
      // Create a proper workbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Responses');
      
      // Create separate sheets for repeatable items and section questions
      if (exportFormat === 'xlsx') {
        setProgress(null); // Switch to indeterminate progress
        await addRepeatableItemsSheet(workbook, questions, submissions);
        await addSectionQuestionsSheet(workbook, questions, submissions);
      }
      
      // Generate file based on format
      if (exportFormat === 'csv') {
        const csvOutput = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
        const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, `${formTitle || 'Form'}_Responses.csv`);
      } else {
        const excelOutput = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelOutput], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${formTitle || 'Form'}_Responses.xlsx`);
      }
      
      console.log(`${exportFormat.toUpperCase()} export completed successfully`);
      setProgress(100);
      
      // Reset progress after a moment
      setTimeout(() => {
        setProgress(null);
      }, 1000);
    } catch (error) {
      console.error(`Error generating ${exportFormat.toUpperCase()} file:`, error);
      setProgress(null);
    } finally {
      setTimeout(() => {
        setExporting(false);
      }, 1000);
    }
  };

  // Modified main export functions to use the optimized approach
  const exportSubmissionsToCsv = (
    formTitle: string,
    questions: any[],
    submissions: any[]
  ) => {
    // Use the optimized approach with CSV format
    exportLargeDataset(formTitle, questions, submissions, 'csv');
  };

  const exportSubmissionsToExcel = (
    formTitle: string, 
    questions: any[], 
    submissions: any[]
  ) => {
    // Use the optimized approach with XLSX format
    exportLargeDataset(formTitle, questions, submissions, 'xlsx');
  };

  // Export individual submission to PDF
  const exportSubmissionToPdf = async (
    formId: string,
    form: any,
    submission: any,
    theme: any
  ) => {
    setExporting(true);
    try {
      // Prepare all data including follow-ups and comments
      const preparedSubmission = {
        responses: submission.responses || {},
        repeatableItems: submission.repeatableItems || {},
        signatures: submission.signatures || {},
        imagePreview: submission.imagePreview || {},
        annotatedImages: submission.annotatedImages || {},
        comments: submission.comments || {}, // Explicitly include comments
      };
      
      // Add comment data to responses if they exist separately
      if (submission.comments) {
        Object.entries(submission.comments).forEach(([questionId, comment]) => {
          if (comment) {
            preparedSubmission.responses[`${questionId}_comment`] = comment;
          }
        });
      }
      
      // Generate PDF with the modular system
      const pdfDoc = await generatePdf(
        formId,
        form,
        preparedSubmission.responses,
        preparedSubmission.repeatableItems,
        preparedSubmission.signatures,
        preparedSubmission.imagePreview,
        preparedSubmission.annotatedImages,
        form.companyLogo || null,
        theme
      );
      
      // Create a meaningful filename
      const safeFormTitle = (form.formTitle || 'Form')
        .replace(/[^a-zA-Z0-9-_]/g, '_')
        .replace(/_+/g, '_');
      const timestamp = format(new Date(), 'yyyyMMdd_HHmm');
      
      // Save the PDF
      if (pdfDoc && typeof pdfDoc.save === 'function') {
        pdfDoc.save(`${safeFormTitle}_${submission.id}_${timestamp}.pdf`);
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setExporting(false);
    }
  };

  // Add this new function to export repeatable items to a separate sheet
  const addRepeatableItemsSheet = async (
    workbook: any,
    questions: any[],
    submissions: any[]
  ) => {
    // Find all repeatable questions
    const repeatableQuestions = questions.filter(q => q.type === 'repeatable');
    
    // If we don't have any repeatable questions, we can skip this
    if (repeatableQuestions.length === 0) return;
    
    for (const question of repeatableQuestions) {
      // Skip if no subfields are defined
      if (!question.subFields || !Array.isArray(question.subFields) || question.subFields.length === 0) continue;
      
      // Create headers for this question's sheet
      const headers = ['Submission ID', 'Email', 'Submission Date', 'Item Index'];
      
      // Add subfield headers
      question.subFields.forEach((subField: {
        label?: string;
        id: string;
      }) => {
        headers.push(subField.label || subField.id);
      });
      
      // Process submissions in batches to collect repeatable items
      const allItems: any[] = [];
      const batchSize = 50;
      const totalBatches = Math.ceil(submissions.length / batchSize);
      
      for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
        const start = batchIndex * batchSize;
        const end = Math.min(start + batchSize, submissions.length);
        const batchSubmissions = submissions.slice(start, end);
        
        // Process each submission in the batch
        for (const submission of batchSubmissions) {
          // Get submission metadata
          const submissionId = submission.id;
          const email = submission.email || 'Anonymous';
          const submissionDate = submission.submittedAt?.toDate
            ? submission.submittedAt.toDate()
            : convertToDate(submission.submittedAt);
          const submissionDateStr = format(submissionDate || new Date(), 'MMM d, yyyy h:mm a');
          
          // Try to get items from repeatableItems (preferred location)
          let items = submission?.repeatableItems?.[question.id];
          
          // If not found there, check in responses
          if (!items || !Array.isArray(items) || items.length === 0) {
            items = Array.isArray(submission.responses?.[question.id]) 
              ? submission.responses[question.id] 
              : [];
          }
          
          // Also check for object with numeric keys pattern
          if ((!items || items.length === 0) && 
              typeof submission.responses?.[question.id] === 'object' && 
              submission.responses?.[question.id] !== null) {
            const response = submission.responses[question.id];
            const possibleArrayItems = Object.keys(response)
              .filter(key => !isNaN(Number(key)))
              .map(key => response[key]);
              
            if (possibleArrayItems.length > 0) {
              items = possibleArrayItems;
            }
          }
          
          // If we found items, add them to our collection
          if (items && Array.isArray(items) && items.length > 0) {
            items.forEach((item, index) => {
              // Add one row per item with metadata
              allItems.push({
                submissionId,
                email,
                submissionDate: submissionDateStr,
                itemIndex: index + 1,
                ...item
              });
            });
          }
        }
        
        // Give the browser a chance to breathe between batches
        if (batchIndex < totalBatches - 1) {
          await new Promise(resolve => setTimeout(resolve, 0));
        }
      }
      
      // If we have items, create a worksheet
      if (allItems.length > 0) {
        // Convert items to rows
        const rows = allItems.map(item => {
          const row: any[] = [
            item.submissionId,
            item.email,
            item.submissionDate,
            item.itemIndex
          ];
          
          // Add data for each subfield
          question.subFields.forEach((subField: { id: string; }) => {
            let value: string | boolean | Date | any[] | object | null = item[subField.id];
            
            // Format the value appropriately
            if (value === undefined || value === null) {
              value = '';
            } else if (typeof value === 'boolean') {
              value = value ? 'Yes' : 'No';
            } else if (Array.isArray(value)) {
              value = value.join(', ');
            } else if (value instanceof Date) {
              value = format(value, 'MMM d, yyyy');
            } else if (typeof value === 'object' && value !== null) {
              try {
                value = JSON.stringify(value);
              } catch (e) {
                value = '[Complex Object]';
              }
            }
            
            row.push(value);
          });
          
          return row;
        });
        
        // Create worksheet
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        
        // Apply styling to headers
        const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1');
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_col(C) + '1';
          if (!worksheet[address]) continue;
          worksheet[address].s = {
            font: { bold: true },
            fill: { fgColor: { rgb: 'E9E9E9' } }
          };
        }
        
        // Auto-size columns
        worksheet['!cols'] = Array(headers.length).fill({ wch: 18 });
        
        // Add the worksheet to the workbook
        const safeSheetName = (question.label || `Repeatable ${question.id}`)
          .replace(/[^a-zA-Z0-9-_]/g, '_')
          .substring(0, 31); // Excel has a 31 character limit for sheet names
        
        XLSX.utils.book_append_sheet(workbook, worksheet, safeSheetName);
      }
    }
    
    return workbook;
  };

  // Add a new function to export section questions to a dedicated sheet
  const addSectionQuestionsSheet = async (
    workbook: any,
    questions: any[],
    submissions: any[]
  ) => {
    // Find all section questions
    const sectionQuestions = questions.filter(q => q.type === 'section');
    
    // If we don't have any section questions, we can skip this
    if (sectionQuestions.length === 0) return;
    
    // Extract all section_q_[number] patterns from all submissions
    const sectionResponseMapping = new Map<string, Set<string>>();
    
    sectionQuestions.forEach(section => {
      sectionResponseMapping.set(section.id, new Set());
    });
    
    // Process submissions in batches
    const batchSize = 100;
    const totalBatches = Math.ceil(submissions.length / batchSize);
    
    for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
      const start = batchIndex * batchSize;
      const end = Math.min(start + batchSize, submissions.length);
      const batchSubmissions = submissions.slice(start, end);
      
      // Scan all submissions in this batch for section_q_[number] patterns
      for (const submission of batchSubmissions) {
        if (submission.responses) {
          Object.keys(submission.responses).forEach(key => {
            const match = key.match(/^section_q_(\d+)$/);
            if (match && match[1]) {
              const sectionQuestionId = match[1];
              // Add to all sections for now, we'll filter later as needed
              sectionQuestions.forEach(section => {
                const sectionSet = sectionResponseMapping.get(section.id);
                if (sectionSet) {
                  sectionSet.add(sectionQuestionId);
                }
              });
            }
          });
        }
      }
      
      // Give the browser a chance to breathe between batches
      if (batchIndex < totalBatches - 1) {
        await new Promise(resolve => setTimeout(resolve, 0));
      }
    }
    
    // For each section, create a dedicated sheet with all its response data
    for (const section of sectionQuestions) {
      const sectionQuestionIds = sectionResponseMapping.get(section.id);
      
      if (!sectionQuestionIds || sectionQuestionIds.size === 0) {
        console.log(`No responses found for section ${section.id}`);
        continue; // Skip this section if no responses
      }
      
      // Create headers for this section's sheet
      const headers = ['Submission ID', 'Email', 'Submission Date'];
      
      // Add a column for each section question
      Array.from(sectionQuestionIds).forEach(sectionQuestionId => {
        headers.push(`Question ${sectionQuestionId}`);
      });
      
      // Collect all items from all submissions
      const rows: any[] = [];
      
      // Process submissions in batches
      for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
        const start = batchIndex * batchSize;
        const end = Math.min(start + batchSize, submissions.length);
        const batchSubmissions = submissions.slice(start, end);
        
        for (const submission of batchSubmissions) {
          if (!submission.responses) continue;
          
          // Check if this submission has any section responses
          const hasSectionResponses = Object.keys(submission.responses).some(key => 
            key.match(/^section_q_\d+$/)
          );
          
          if (!hasSectionResponses) continue;
          
          // Create a row for this submission
          const row = [
            submission.id || 'Unknown',
            submission.email || 'Anonymous',
            submission.submittedAt 
              ? format(convertToDate(submission.submittedAt) || new Date(), 'MMM d, yyyy h:mm a')
              : 'Unknown date'
          ];
          
          // Add data for each section question
          Array.from(sectionQuestionIds).forEach(sectionQuestionId => {
            const key = `section_q_${sectionQuestionId}`;
            let value = submission.responses[key] || '';
            
            // Format value appropriately
            if (typeof value === 'object' && value !== null) {
              try {
                value = JSON.stringify(value);
              } catch (e) {
                value = '[Complex Object]';
              }
            }
            
            row.push(value);
          });
          
          rows.push(row);
        }
        
        // Give the browser a chance to breathe between batches
        if (batchIndex < totalBatches - 1) {
          await new Promise(resolve => setTimeout(resolve, 0));
        }
      }
      
      // If we have rows, create a worksheet
      if (rows.length > 0) {
        // Create worksheet
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        
        // Apply styling to headers
        const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1');
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_col(C) + '1';
          if (!worksheet[address]) continue;
          worksheet[address].s = {
            font: { bold: true },
            fill: { fgColor: { rgb: 'E9E9E9' } }
          };
        }
        
        // Add the worksheet to the workbook
        const safeSheetName = (section.label || `Section ${section.id}`)
          .replace(/[^a-zA-Z0-9-_]/g, '_')
          .substring(0, 31); // Excel has a 31 character limit for sheet names
        
        XLSX.utils.book_append_sheet(workbook, worksheet, safeSheetName);
      }
    }
    
    return workbook;
  };

  return {
    exporting,
    progress,
    exportSubmissionsToPdf: exportSubmissionToPdf,
    exportSubmissionsToExcel,
    exportSubmissionsToCsv: exportSubmissionsToCsv
  };
};